import { Component, inject, OnDestroy, ViewChild } from '@angular/core';
import { CommonModule } from '@angular/common';
import { IonicModule, IonModal } from '@ionic/angular';
import { Router } from '@angular/router';
import { MOBILE_ROUTE_PATHS } from '../../../../app.routes';
import { FormsModule } from '@angular/forms';
import { AmountNumberPadModalComponent } from './components/amount-number-pad-modal/amount-number-pad-modal.component';
import {
  SignaturePadBase64Image,
  SignaturePadModalComponent,
} from './components/signature-pad-modal/signature-pad-modal.component';
import { InputComponent } from '../../../../core/components/input/input.component';
import {
  ProductAmountGridComponent,
  ProductInputChangeEvent,
  ProductInputFocusEvent,
  QuantityType,
} from './components/product-amount-grid/product-amount-grid.component';
import * as dayjs from 'dayjs';
import 'dayjs/locale/de';
import { DeliveryDetailsPageService } from '../../delivery-details-page.service';
import {
  DeliveryDraft,
  IDeliveryProduct,
  IDriverDelivery,
  IDriverDeliveryProduct,
  SignaturePackage
} from '@prf/shared/domain';
import { FormatDatePipe } from '../../../../shared/pipes/format-date.pipe';
import {
  LocalDeliveryDataSyncStatus,
  ProcessedDelivery,
} from '../../../../shared/services/db/app-indexed-db.service';
import { from, interval, Subject, switchMap, take, takeUntil, tap } from 'rxjs';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { AlertController } from '@ionic/angular/standalone';
import { dataUrlToEpsonMonoImageTag } from '../../../../shared/services/print/epos-image-mono-encoder';
import { EpsonPrintCommunicationService } from '../../../../shared/services/print/epson-print-communication.service';
import { DeliveryDraftService } from '../../../../shared/services/data/delivery-draft.service';

dayjs.locale('de');

@Component({
  selector: 'prf-delivery-slip-tab',
  templateUrl: './delivery-slip-tab.component.html',
  styleUrls: ['./delivery-slip-tab.component.scss'],
  standalone: true,
  imports: [
    IonicModule,
    CommonModule,
    FormsModule,
    AmountNumberPadModalComponent,
    SignaturePadModalComponent,
    InputComponent,
    ProductAmountGridComponent,
    FormatDatePipe,
  ],
})
export class DeliverySlipTabComponent implements OnDestroy {
  private router = inject(Router);
  private alertController = inject(AlertController);
  private printService = inject(EpsonPrintCommunicationService);
  private deliveryDraftService = inject(DeliveryDraftService);
  protected deliveryDetailsPageService = inject(DeliveryDetailsPageService);
  protected currentDelivery$ = this.deliveryDetailsPageService.delivery$;
  private currentDeliveryId: number | null = null;

  selectedTitle: 'Herr' | 'Frau' | 'keine Angabe' = 'Herr';

  wizardStep = 1;

  @ViewChild('amountNumberPadModal') amountNumberPadModal!: IonModal;
  @ViewChild('signaturePadModal') signaturePadModal!: IonModal;

  @ViewChild(ProductAmountGridComponent) productAmountGridComp!: ProductAmountGridComponent;

  isSignatureModalOpen = false;
  isNumberPadModalOpen = false;

  amountGridFocusedItem: Partial<IDriverDeliveryProduct> | null = null;
  amountGridFocusedQuantityType: QuantityType | null = null;

  currentTimeDate = new Date();
  private stopTimeUpdates = new Subject<void>();

  private signaturePackage: SignaturePackage | null = null;

  constructor() {
    this.deliveryDetailsPageService.closeNumberPadModal$
      .pipe(takeUntilDestroyed())
      .subscribe((_) => {
        this.isNumberPadModalOpen = false;
      });

    this.currentDelivery$
      .pipe(takeUntilDestroyed())
      .subscribe((delivery) => {
        this.currentDeliveryId = delivery.id;
      });
  }

  signaturePersonName: string = '';

  messageFromMarketToOffice: string = '';

  protected deliveryProducts: Partial<IDriverDeliveryProduct>[] = [];

  ngOnInit() {
    this.startUpdatingCurrentTime();

    // Load existing draft if available
    this.currentDelivery$.pipe(
      take(1),
      tap(delivery => {
        // Initialize products from delivery
        this.deliveryProducts = delivery.deliveryProducts;
      }),
      switchMap(delivery => from(this.deliveryDraftService.loadDraft(delivery.id))),
    ).subscribe(draft => {
      if (draft) {
        this.restoreDraft(draft);
      }
    });
  }

  ngOnDestroy() {
    this.stopTimeUpdates.next();
    this.stopTimeUpdates.complete();
  }

  onProductsChange(products: Partial<IDriverDeliveryProduct>[]) {
    this.deliveryProducts = products;
  }

  startUpdatingCurrentTime() {
    interval(15_000) // Emit every 15 seconds
      .pipe(takeUntil(this.stopTimeUpdates))
      .subscribe(() => {
        this.currentTimeDate = new Date();
      });
  }

  getSignatureBase64Data(): string | null {
    return this.signaturePackage?.base64 || null;
  }

  getSignaturePersonName(): string {
    if (this.selectedTitle === 'keine Angabe') {
      return `(keine Angabe) ${this.signaturePersonName}`;
    } else {
      return `${this.selectedTitle} ${this.signaturePersonName}`;
    }
  }

  onClickNextStep() {
    this.wizardStep = this.wizardStep + 1;
  }

  onClickPrevStep() {
    this.wizardStep = this.wizardStep - 1;
  }

  // TODO: rename, as this not only presents alert, but also COMPELTED the delivery
  //       or better: make it only confirm... awaitable result... if true (in parent) -> call completeDelivery.
  async presentConfirmationAlert(delivery: IDriverDelivery) {
    const alert = await this.alertController.create({
      header: 'Lieferung abschließen',
      // message: 'Jetzt abschließen?',
      backdropDismiss: false,
      animated: true,
      buttons: [
        {
          text: 'Abbrechen',
          role: 'cancel',
          handler: () => {
            console.log('Cancel action');
          },
        },
        {
          text: 'Bestätigen',
          role: 'confirm',
          handler: () => {
            // TODO: Extract into vibrate service - success vibrate
            navigator.vibrate(150); // Vibrate for 150ms.
            this.completeDelivery(delivery);
          },
        },
      ],
    });

    await alert.present();
  }

  private completeDelivery(delivery: IDriverDelivery) {
    this.stopTimeUpdates.next();

    if (!this.signaturePackage) {
      throw new Error('Signature is required to complete delivery');
    }

    // Generate the print XML for the finished delivery
    const receiptData = this.printService['prepareReceiptData'](
      this.getCurrentDeliveryData(delivery),
      false, // isDraft = false
      this.signaturePackage
    );
    const printXml = this.printService['xmlService'].generateXmlData(receiptData);

    const processedDelivery: ProcessedDelivery = {
      deliveryId: delivery.id,
      messageFromMarket: this.messageFromMarketToOffice,
      signature: this.signaturePackage,
      deliveredAtMarketDate: this.currentTimeDate,
      deliveryProducts: this.deliveryProducts.map(
        (dP: Partial<IDeliveryProduct> & { __typename?: string }) => {
          delete dP.__typename;
          return dP;
        },
      ),
      syncStatus: LocalDeliveryDataSyncStatus.Pending,
      printXml: printXml, // Store the generated XML
    };

    this.finishDelivery(delivery, processedDelivery);


    // Safely delete the draft since we're finishing the delivery
    this.deliveryDraftService.deleteDraft(delivery.id).catch(error => {
      console.error('Error deleting draft:', error);
    });
  }

  onClickFinishDelivery(delivery: IDriverDelivery) {
    this.presentConfirmationAlert(delivery);
  }

  shouldShowPrevButton(): boolean {
    return this.wizardStep > 1;
  }

  shouldShowNextStepButton() {
    return this.wizardStep > 0 && this.wizardStep < 3;
  }

  shouldShowFinishDelivery() {
    return this.wizardStep === 3;
  }

  onDidDismissNumPadModal() {
    this.isNumberPadModalOpen = false;
    this.amountGridFocusedItem = null;
    this.amountGridFocusedQuantityType = null;
  }

  onDidDismissSignaturePadModal($event: { detail: { data: SignaturePadBase64Image } }) {
    const signatureBase64 = $event.detail.data.signatureBase64!;

    dataUrlToEpsonMonoImageTag(signatureBase64, {
      maxWidth: 370,
      maxHeight: 80,
    })
      .then((result) => {
        if (result) {
          this.signaturePackage = {
            base64: signatureBase64,
            monoImageTag: result.tag,
            width: result.width,
            signaturePerson: this.getSignaturePersonName()
          };
          // this.saveDraft();
        }
      })
      .catch((error: any) => {
        console.error('Error processing signature:', error);
      });

    this.isSignatureModalOpen = false;
  }

  setFocussedAmountGridItem(event: ProductInputFocusEvent) {
    this.amountGridFocusedItem = event.item;
    this.isNumberPadModalOpen = true;
    this.amountGridFocusedQuantityType = event.quantityType;
  }

  onNumberPadNumberChangeEvent($event: ProductInputChangeEvent) {
    if (this.amountGridFocusedItem && this.amountGridFocusedQuantityType) {
      this.productAmountGridComp.updateQuantity(
        this.amountGridFocusedItem,
        this.amountGridFocusedQuantityType,
        $event.value
      );
    }
  }

  onNumberPadConfirm(): void {
    this.productAmountGridComp.handleConfirmItem();
  }

  onNumberPadNext(): void {
    this.productAmountGridComp.handleNextItem();
  }

  hasSignature(): boolean {
    return !!this.signaturePackage?.base64;
  }

  isFinishDeliveryDisabled(currentDelivery: IDriverDelivery): boolean {
    if (currentDelivery.isDelivered) {
      return true;
    }

    return !this.signaturePersonName || !this.hasSignature();
  }

  private async finishDelivery(originalDelivery: IDriverDelivery, processedDelivery: ProcessedDelivery) {
    try {
      // Wait for the write to complete
      await this.deliveryDetailsPageService.finishDelivery(processedDelivery);

      // Only navigate after successful write
      this.router.navigate(
        [MOBILE_ROUTE_PATHS.DELIVERY_DONE_DETAILS + '/' + processedDelivery.deliveryId],
        {
          state: {
            originalDelivery,
            processedDelivery,
          }
        }
      );
    } catch (error) {
      console.error('Error finishing delivery:', error);
      // TODO: Show error to user
    }
  }

  titelizeNameString(value: string): string {
    return value.replace(/(^|\s|-)(\p{L})/gu, (match, prefix, char) => prefix + char.toUpperCase());
  }

  onSignaturePersonNameChange(value: string): void {
    this.signaturePersonName = this.titelizeNameString(value).trim();
    // this.saveDraft();
  }

  async onClickPrintDraftDeliverySlip(currentDelivery: IDriverDelivery): Promise<void> {

    // TODO: draft print confirm dialog !

    console.log('onClickPrintDraftDeliverySlip - currentDelivery', currentDelivery);
    try {
      const draft = this.prepareDraft(currentDelivery);
      console.log('onClickPrintDraftDeliverySlip - draft', draft);
      const saveSuccess = await this.deliveryDraftService.saveDraft(draft);

      if (!saveSuccess) {
        console.log('onClickPrintDraftDeliverySlip - _NO_ SAVE SUCCESS!');
        const alert = await this.alertController.create({
          header: 'Fehler',
          message: 'Speichern des Entwurfs fehlgeschlagen. Druckvorgang abgebrochen.',
          buttons: ['OK']
        });
        await alert.present();
        return;
      }
      console.log('onClickPrintDraftDeliverySlip - SAVE SUCCESS');

      // Only proceed with printing if save was successful
      this.printService.printDeliverySlip(
        this.getCurrentDeliveryData(currentDelivery),
        true,
        this.signaturePackage
      );

    } catch (error) {
      console.error('Error in print process:', error);
      const alert = await this.alertController.create({
        header: 'Fehler beim Drucken (DRS)',
        message: 'Ein unerwarteter Fehler ist aufgetreten. Bitte versuchen Sie es erneut.',
        buttons: ['OK']
      });
      await alert.present();
    }
  }

  private getCurrentDeliveryData(currentDelivery: IDriverDelivery): IDriverDelivery {
    return {
      ...currentDelivery,
      deliveryProducts: this.deliveryProducts,  // Use state directly
    }
  }

  private prepareDraft(currentDelivery: IDriverDelivery): Omit<DeliveryDraft, 'lastModified'> {
    console.warn('prepareDraft - currentDelivery', currentDelivery);
    return {
      deliveryId: currentDelivery.id,
      deliveryProducts: this.deliveryProducts,  // Use state directly
      signature: {
        package: this.signaturePackage,
        person: {
          title: this.selectedTitle,
          name: this.signaturePersonName
        }
      },
      messageFromMarket: this.messageFromMarketToOffice
    };
  }

  private restoreDraft(draft: DeliveryDraft) {
    console.log('restoreDraft - draft', draft);
    this.signaturePackage = draft.signature.package;
    this.selectedTitle = draft.signature.person.title;
    this.signaturePersonName = draft.signature.person.name;
    this.messageFromMarketToOffice = draft.messageFromMarket;
    this.deliveryProducts = draft.deliveryProducts;
  }

}

// check: active tab index während wizard steps durchgangen werden

// num pad +/- und custom tab-order. innerhalb mengen und retourenspalte springen. nicht in dom-order
// aktuellen fokus besonders stark  hervorheben, ggf noch hintergrund
// retouren mit 0 initialisieren
// nach bestätigung einer mengeneingabe (row), den input besonders hervorheben, zB grüne outline
// SPALTE (menge/retoure), in dem keypad gerade aktiv ist, besonders hervorheben

