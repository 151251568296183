import { PRINT_CONFIG, WORD_REPLACEMENTS } from './epson-print.config';

export function formatColumn(text: string, width: number, align: 'left' | 'right' = 'left'): string {
  const ellipsis = '...';
  if (text.length > width) {
    if (align === 'left') {
      return text.substring(0, width - ellipsis.length) + ellipsis;
    } else {
      return ellipsis + text.substring(text.length - width + ellipsis.length);
    }
  } else {
    return align === 'left' ? text.padEnd(width) : text.padStart(width);
  }
}

export function replaceKeywords(text: string): string {
  const words = text.split(/\s+/);
  const replacedWords = words.map((word) => {
    const lowerWord = word.toLowerCase();
    for (const [key, value] of Object.entries(WORD_REPLACEMENTS)) {
      if (lowerWord === key.toLowerCase()) {
        const replaced =
          word[0] === word[0].toUpperCase()
            ? value[0].toUpperCase() + value.slice(1)
            : value.toLowerCase();
        return replaced;
      }
    }
    return word;
  });

  return replacedWords.join(' ');
}

export function generateSeparator(): string {
  return `<text>${'-'.repeat(PRINT_CONFIG.columns)}</text>`;
}
