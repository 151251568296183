// TODO: Check: should this remain in shared? or rather be put into deliv details page or near deliv slip tab comp?

import { AppIndexedDb } from '../db/app-indexed-db.service';
import { inject, Injectable } from '@angular/core';
import { DeliveryDraft } from '@prf/shared/domain';
import { debounceTime, Subject, switchMap, tap } from 'rxjs';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';

@Injectable({
  providedIn: 'root',
})
export class DeliveryDraftService {
  private db = inject(AppIndexedDb);
  private draftSaveSubject = new Subject<Omit<DeliveryDraft, 'id' | 'lastModified'>>();

  // Observable that emits the save operation result
  private draftSaveResult$ = this.draftSaveSubject.pipe(
    tap(val => console.log("inside PIPE $ ", val)),
    debounceTime(500),
    switchMap(async (draft): Promise<boolean> => {
      try {
        await this.db.saveDeliveryDraft(draft);
        return true;
      } catch (error) {
        console.error('Error saving draft:', error);
        return false;
      }
    })
  );

  constructor() {
    // Keep the subscription alive to process saves
    this.draftSaveResult$.pipe(
      takeUntilDestroyed()
    ).subscribe(result => {
      console.log('Save result:', result);
    });
  }

  async saveDraft(draft: Omit<DeliveryDraft, 'lastModified'>): Promise<boolean> {
    return new Promise((resolve) => {
      const subscription = this.draftSaveResult$.subscribe(success => {
        console.log('Save completed with result:', success);
        resolve(success);
        subscription.unsubscribe();
      });

      this.draftSaveSubject.next(draft);
    });
  }

  async loadDraft(deliveryId: number): Promise<DeliveryDraft | undefined> {
    return await this.db.getDeliveryDraft(deliveryId);
  }

  async deleteDraft(deliveryId: number): Promise<void> {
    await this.db.deleteDeliveryDraft(deliveryId);
  }
}
