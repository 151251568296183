import { Injectable } from '@angular/core';
import { SignaturePackage } from '@prf/shared/domain';
import { PrintDeliveryProduct, PrintReceiptData } from './epson-print.types';
import { formatColumn, generateSeparator, replaceKeywords } from './epson-print-formatting.utils';
import { PRINT_CONFIG } from './epson-print.config';

@Injectable({
  providedIn: 'root',
})
export class EpsonPrintXmlService {
  constructor() {}

  public generateXmlData(receiptData: PrintReceiptData): string {
    let xml = this.generateBaseXml(receiptData);

    // Remove all empty lines and leading whitespace
    xml = xml.replace(/^\s*[\r\n]/gm, '');
    xml = xml.replace(/^\s+/gm, '');

    return xml;
  }

  private generateBaseXml(receiptData: PrintReceiptData): string {
    return `
      <epos-print xmlns="http://www.epson-pos.com/schemas/2011/03/epos-print">
        ${this.generatePrintSetup()}
        ${this.generatePaldoLogo()}
        <feed unit="3"/>
        ${this.generateCompanyHeader(receiptData.companyInfo)}
        ${this.generateDeliveryHeader(receiptData)}
        ${this.generateMarketSection(receiptData)}
        ${this.generateDraftTopSection(receiptData.isDraft)}
        ${this.generateProductsSection(receiptData.products, 'center')}
        ${this.generateTotalAndTemperatureSection(
          receiptData.totalActualQuantity,
          receiptData.totalReturnQuantity,
        )}
        ${this.generateSignatureSection(receiptData.isDraft, receiptData.signature)}
        ${this.generateTimestamp(receiptData.currentDate, receiptData.currentTime)}
        ${this.generateDraftBottomLegalHintSection(receiptData.isDraft)}
        ${this.generateEmailSection(receiptData.marketDeliverySlipEmail)}
        <cut type="feed"/>
      </epos-print>
    `;
  }

  private generatePrintSetup(): string {
    return `
      <layout type="receipt" width="800" height="0" margin-top="20" margin-bottom="0" offset-cut="0" offset-label="0"/>
      <text lang="${PRINT_CONFIG.lang}"/>
      <text font="${PRINT_CONFIG.font}"/>
      <text smooth="${PRINT_CONFIG.fontSmoothing}"/>
      <text width="1" height="1"/>
    `;
  }

  private generateCompanyHeader(companyInfo: PrintReceiptData['companyInfo']): string {
    return `
      <text align="center"/>
      <text>${companyInfo.name}</text>
      <feed line="1"/>
      <text>${companyInfo.address}</text>
      <feed line="1"/>
      <text>T: ${companyInfo.phone}   E: ${companyInfo.email}</text>
      <feed line="1"/>
      <feed unit="5"/>
    `;
  }

  private generateDeliveryHeader(receiptData: PrintReceiptData): string {
    return `
      <text width="2" height="2"/>
      <text>Lieferschein</text>
      <feed line="1"/>
      <text width="2" height="2"/>
      <text em="true">${receiptData.deliverySlipNo}</text>
      <feed line="1"/>
      <text ul="false"></text>
      <text width="1" height="1"/>
      <text>vom ${receiptData.deliveryDate}</text>
      <text em="false"></text>
      <feed line="1"/>
    `;
  }

  private generateMarketSection(receiptData: PrintReceiptData): string {
    return `
      <text>${receiptData.marketName}</text>
      <feed line="1"/>
      <text>${receiptData.marketAddress}</text>
      <feed line="1"/>
    `
  }

  private generateDraftTopSection(isDraft: boolean): string {
    const EMPTY_SPACE = ' ';

    if (!isDraft) return '';
    return `
      <text width="2" height="2"/>
      <text reverse="true" ul="false" em="false"/>
      <text align="center">${EMPTY_SPACE}ENTWURF${EMPTY_SPACE}</text>
      <text width="1" height="1"/>
      <text reverse="false" ul="false" em="false"/>
      <feed line="1"/>
    `;
  }

  private generateProductsSection(
    products: PrintDeliveryProduct[],
    barcodeAlignment: 'left' | 'center' | 'right',
  ): string {
    const PRODUCT_NO_WIDTH = 9;
    const DESCRIPTION_WIDTH = 29;
    const QUANTITY_WIDTH = 10;

    if (PRINT_CONFIG.columns !== PRODUCT_NO_WIDTH + DESCRIPTION_WIDTH + QUANTITY_WIDTH) {
      window.alert('ERROR - EPSON Print: Column widths do not match max width.');
      throw new Error('EPSON print: Column widths do not match max width.');
    }

    // Offset used in order to allow to description rows "blend" into the quantity col
    const DESCR_QUANT_HEADER_COL_OFFSET = 8;
    const header = `<text>${
      formatColumn('Art-Nr.', PRODUCT_NO_WIDTH) +
      formatColumn('Bezeichnung', DESCRIPTION_WIDTH - DESCR_QUANT_HEADER_COL_OFFSET) +
      formatColumn('Lieferm./Rückn.', QUANTITY_WIDTH + DESCR_QUANT_HEADER_COL_OFFSET, 'right')
    }</text>`;

    const DESCR_QUANT_ROW_COL_OFFSET = 3;
    const productLines = products
      .map((product) => {
        const replacedDescription = replaceKeywords(product.description);

        return `
            <text em="true">${formatColumn(product.productNo, PRODUCT_NO_WIDTH)}</text>
            <text em="false">${formatColumn(replacedDescription, DESCRIPTION_WIDTH + DESCR_QUANT_ROW_COL_OFFSET)}</text>
            <text em="true">${formatColumn(
          `${product.actualQuantity}/${product.returnQuantity}`,
          QUANTITY_WIDTH - DESCR_QUANT_ROW_COL_OFFSET,
          'right',
        )}</text>
          <text em="false"></text>
          <feed line="1"/>
          <feed unit="2"/>
          <barcode type="ean13" hri="none" font="font_a" width="4" height="32" align="${barcodeAlignment}">${product.ean}</barcode>
          ${generateSeparator()}
          <feed line="1"/>
        `;
      })
      .join('\n');

    return `
      <text align="left"/>
      ${header}
      <feed line="1"/>
      ${generateSeparator()}
      <feed line="1"/>
      ${productLines}
    `;
  }

  private generateTotalAndTemperatureSection(
    totalActualQuantity: number,
    totalReturnQuantity: number,
  ): string {
    const LEFT_COL_WIDTH = 36;
    const RIGHT_COL_WIDTH = 12;

    return `
      <text>${
        formatColumn('Die Kühltemperatur von 3°C - 7°C', LEFT_COL_WIDTH) +
        formatColumn('Gesamt', RIGHT_COL_WIDTH, 'right')
      }</text>
      <feed line="1"/>
      <text>${formatColumn('wurde eingehalten.', LEFT_COL_WIDTH)}</text>
      <text em="true">${formatColumn(
        `${totalActualQuantity}/${totalReturnQuantity}`,
        RIGHT_COL_WIDTH,
        'right',
      )}</text>
      <text em="false"></text>
      <feed line="1"/>
    `;
  }

  private generateSignatureSection(isDraft: boolean, signature: SignaturePackage | null): string {
    if (isDraft || !signature) return '';

    return `
      <text align="left">Die Lieferung wurde geprüft, entgegengenommen&#10;und unterschrieben von:</text>
      <feed line="1"/>
      ${signature.monoImageTag}
      <feed unit="2"/>
      <hline x1="0" x2="${(signature.width || 110) + 16}" style="medium" />
      <feed unit="1"/>
      <text> ${signature.signaturePerson}</text>
      <feed line="1"/>
    `;
  }

  private generateDraftBottomLegalHintSection(isDraft: boolean): string {
    if (!isDraft) return '';
    return `
      <feed line="1"/>
      <text align="left">Lieferschein vor Lieferungsabschluss (Entwurf), dieser Beleg ist nicht rechtsverbindlich.</text>
      <feed line="1"/>
    `;
  }

  private generateEmailSection(email: string): string {
    return `
      <feed line="1"/>
      <text em="true" align="left">Dieser Lieferschein wird als PDF an die E-Mail-&#10;Adresse ${email} gesendet.</text>
      <text em="false"></text>
      <feed line="2"/>
    `;
  }

  private generateTimestamp(date: string, time: string): string {
    return `
      <feed line="1"/>
      <text>${formatColumn(date, 28) + formatColumn(`Uhrzeit: ${time}`, 20, 'right')}</text>
      <feed line="1"/>
    `;
  }

  private generatePaldoLogo(): string {
    return '<image width="150" height="33" align="center" color="color_1" mode="mono">AAAAAAAAAAAAAAAAAAAAAAAAAP//wAADgAD/AAA///wAAAD/+AAf//gAA4AAP4AAB///gAAD//4AD8B+AAfAAB+AAAPwB/AAB4APAA/APwAHwAAPgAAD8AH4AB8AB8APwB+AB8AAD4AAA/AA/AAfAAfAD8AfgA/gAA+AAAPwAH4APgAD4A/AD8AP4AAPgAAD8AA/AHwAAfAPwA/AH/AAD4AAA/AAP4B8AAHwD8APwBvwAA+AAAPwAB+A/AAB+A/AD8Az8AAPgAAD8AAfwPgAAPgPwA/AMfgAD4AAA/AAH8H4AAD8D8APwHH4AA+AAAPwAA/B+AAA/A/AH4Bh/AAPgAAD8AAP4fgAAPwPwB+AYPwAD4AAA/AAD+H4AAD8D8A/AMD+AA+AAAPwAA/h+AAA/A/AfgDAfgAPgAAD8AAP4fgAAPwPwPwBgH4AD4AAA/AAD+H4AAD8D//wAYA/AA+AAAPwAA/h+AAA/A//AAMAPwAPgAAD8AAP4fgAAPwPwAADAB+AD4AAA/AAD8H4AAD8D8AABwAfgA+AAAPwAB/B+AAA/A/AAAf//4APgAAD8AAfwPgAAPgPwAAH///AD4AAA/AAH4D8AAH4D8AADAAPwA+AAAPwAD+A/AAB+A/AAAwAD+APgAAD8AA/AHwAAfAPwAAYAAfgD4AAA/AAfgA+AAPgD8AAGAAH8A+AAAPwAP4APwAH4A/AADAAA/APgAAD8AH4AB8AB8APwAAwAAH4D4AAA/AD8AAPgA+AD8AAYAAA/A///8P//8AAA//+AA/AAGAAAH8P///D//4AAAD/+AAAAAAAAAAAAAAAAAAAAAAAAAAA</image>';
  }
}
