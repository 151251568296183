import { Injectable } from '@angular/core';
import { IDriverDelivery, SignaturePackage } from '@prf/shared/domain';
import { EpsonPrintXmlService } from './epson-print-xml.service';
import { PrintDeliveryProduct, PrintReceiptData } from './epson-print.types';
import { ProcessedDelivery } from '../db/app-indexed-db.service';

// TODO/NOTES:
// <hline> instead of seperator ---- lines
// https://download4.epson.biz/sec_pubs/pos/reference_en/epos_print/ref_epos_print_xml_en_xmlforcontrollingprinter_hline.html

@Injectable({
  providedIn: 'root',
})
export class EpsonPrintCommunicationService {
  constructor(private xmlService: EpsonPrintXmlService) {}

  public printDeliverySlip(
    delivery: IDriverDelivery,
    isDraft: boolean, // TODO: Refactor: move to last position of arg list. --- or add wrapper method for draft and nonDraft print
    signaturePackage: SignaturePackage | null,
    processedDelivery?: ProcessedDelivery,
  ): void {
    console.log('EPSON COMMUNIC - printDeliverySlip - delivery, isDraft', delivery, isDraft);

    if (!isDraft && processedDelivery?.printXml) {
      // Use stored XML for finished deliveries
      this.printWithTMAssistantFromXml(processedDelivery.printXml);
      return;
    }

    const receiptData = this.prepareReceiptData(delivery, isDraft, signaturePackage, processedDelivery);
    this.printWithTMAssistantFromReceiptData(receiptData);
  }

  private prepareReceiptData(
    delivery: IDriverDelivery,
    isDraft: boolean,
    signaturePackage: SignaturePackage | null,
    processedDelivery?: ProcessedDelivery,
  ): PrintReceiptData {
    const products = this.mapDeliveryProductsToPrintProducts(delivery.deliveryProducts);
    const calculateTotal = (key: 'actualQuantity' | 'returnQuantity'): number => {
      return products.reduce((sum, product) => sum + product[key], 0);
    };

    // Use processed delivery date/time if available and not in draft mode
    // Note: this seems to be a check for IS COMPLETED delivery - check²!
    const currentDateTime = !isDraft && processedDelivery
      ? new Date(processedDelivery.deliveredAtMarketDate)
      : new Date();

    // TODO: extract company info into @shared nx lib, in order to reuse inside PM and also PDF templates.
    return {
      companyInfo: {
        name: 'PALDO GmbH',
        address: 'Heinrich-Brauns-Str. 17, 45355 Essen',
        phone: '0201 17754771',
        email: 'info@paldo.de',
      },
      isDraft,
      deliverySlipNo: delivery.deliverySlipNumber!,
      deliveryDate: new Date(delivery.deliveryDate).toLocaleDateString('de-DE', {
        year: 'numeric',
        month: '2-digit',
        day: '2-digit',
      }),
      products,
      signature: signaturePackage,
      marketDeliverySlipEmail: delivery.marketDeliverySlipEmail,
      currentDate: currentDateTime.toLocaleString('de-DE', {
        weekday: 'short',
        year: 'numeric',
        month: '2-digit',
        day: '2-digit',
      }),
      currentTime: currentDateTime.toLocaleString('de-DE', {
        hour: '2-digit',
        minute: '2-digit',
      }),
      totalActualQuantity: calculateTotal('actualQuantity'),
      totalReturnQuantity: calculateTotal('returnQuantity'),
    };
  }

  private printWithTMAssistantFromReceiptData(receiptData: PrintReceiptData): void {
    console.log('printWithTMAssistantFromReceiptData - receiptData', receiptData);
    const xmlData = this.xmlService.generateXmlData(receiptData);
    this.executePrintWithTMAssistant(xmlData);
  }

  private printWithTMAssistantFromXml(xmlData: string): void {
    console.log('printWithTMAssistantFromXml - xmlData', xmlData);
    this.executePrintWithTMAssistant(xmlData);
  }

  private executePrintWithTMAssistant(xmlData: string): void {
    const appScheme = 'tmprintassistant://';
    const host = 'tmprintassistant.epson.com/';
    const action = 'print?';
    const success = encodeURIComponent(window.location.href);
    const ver = '1';
    const timeout = '45000';
    const dataType = 'eposprintxml';
    const reselect = 'yes';

    const urlData =
      `${appScheme}${host}${action}` +
      `success=${success}&` +
      `ver=${ver}&` +
      `timeout=${timeout}&` +
      `data-type=${dataType}&` +
      `reselect=${reselect}&` +
      `data=${encodeURIComponent(xmlData)}`;

    console.log(xmlData);

    window.location.href = urlData;
  }

  private mapDeliveryProductsToPrintProducts(deliveryProducts: any[]): PrintDeliveryProduct[] {
    return deliveryProducts.map((dp) => ({
      productNo: dp.product.productNo,
      description: dp.product.description,
      actualQuantity: dp.actualQuantity,
      returnQuantity: dp.returnQuantity,
      ean: dp.product.ean,
    }));
  }
}
