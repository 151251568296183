export const PRINT_CONFIG = {
  columns: 48,
  font: 'font_e',
  fontSmoothing: 'true',
  lang: 'de',
} as const;

export const WORD_REPLACEMENTS = {
  Gefüllt: 'Gef.',
  Gefüllte: 'Gef.',
  Getrocknet: 'Getr.',
  Getrocknete: 'Getr.',
  Knoblauch: 'Knobl.',
  ohne: 'o.',
} as const;
