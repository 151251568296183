import { Component, EventEmitter, Input, Output } from '@angular/core';
import { CommonModule } from '@angular/common';
import { InputComponent } from '../../../../../../core/components/input/input.component';
import { FormsModule } from '@angular/forms';
import { IonicModule } from '@ionic/angular';
import { IDriverDeliveryProduct } from '@prf/shared/domain';

export type QuantityType = 'actualQuantity' | 'returnQuantity';

export interface ProductInputFocusEvent {
  item: Partial<IDriverDeliveryProduct>;
  quantityType: QuantityType;
}

export interface ProductInputChangeEvent {
  item: Partial<IDriverDeliveryProduct>;
  quantityType: QuantityType;
  value: number;
}

@Component({
  selector: 'prf-product-amount-grid',
  standalone: true,
  imports: [CommonModule, InputComponent, FormsModule, IonicModule],
  templateUrl: './product-amount-grid.component.html',
  styleUrls: ['./product-amount-grid.component.scss'],
  // Push detection leads to NUMPAD-change -> doesn't immediately lead to amount grid input change
  // changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ProductAmountGridComponent {
  @Input({ required: true })
  items!: Partial<IDriverDeliveryProduct>[]; // TODO: Check for non-partial typing

  @Input()
  isEditable: boolean = true;

  @Output()
  itemFocus = new EventEmitter<ProductInputFocusEvent>();

  @Output()
  productsChange = new EventEmitter<Partial<IDriverDeliveryProduct>[]>();

  focussedItem: Partial<IDriverDeliveryProduct> | null = null;
  focussedQuantityType: QuantityType | null = null;

  updateQuantity(item: Partial<IDriverDeliveryProduct>, quantityType: QuantityType, value: number) {
    item[quantityType] = value;
    this.emitChanges();
  }

  private emitChanges() {
    this.productsChange.emit([...this.items]); // Create new array to ensure change detection
  }

  handleConfirmItem(): void {
    this.toggleCurrentRowsQuantityType();
  }

  handleNextItem(): void {
    // Assuming setFocussedItem correctly resets to actualQuantity
    if (this.focussedItem) {
      const currentIndex = this.items.findIndex(item => item === this.focussedItem);

      // Check if there's a next item
      if (currentIndex !== -1 && currentIndex < this.items.length - 1) {
        const nextItem = this.items[currentIndex + 1];
        // Set the next item as focussedItem
        this.setFocussedItem(nextItem as Partial<IDriverDeliveryProduct>, this.focussedQuantityType!);
      }
    }
  }

  private toggleCurrentRowsQuantityType(): void {
    if (this.focussedQuantityType === 'actualQuantity') {
      this.setFocussedItem(this.focussedItem!, 'returnQuantity')
    } else if(this.focussedQuantityType === 'returnQuantity') {
      this.setFocussedItem(this.focussedItem!, 'actualQuantity')
    }
  }

  // private scrollToFocussedItem(): void {
  //   if (this.focussedItem && this.rows && this.scrollContainer) {
  //     const index = this.items.findIndex(item => item === this.focussedItem);
  //     const rowElement = this.rows.toArray()[index]?.nativeElement;
  //
  //     if (rowElement) {
  //       const elementTop = rowElement.offsetTop;
  //       const elementBottom = elementTop + rowElement.offsetHeight;
  //       const containerTop = this.scrollContainer.scrollTop;
  //       const containerBottom = containerTop + this.scrollContainer.offsetHeight;
  //
  //       // Check if the element is fully in view
  //       if (elementTop < containerTop || elementBottom > containerBottom) {
  //         // Not in view so scroll
  //         this.scrollContainer.scroll({
  //           top: elementTop - this.scrollContainer.offsetTop - (this.scrollContainer.offsetHeight / 2) + (rowElement.offsetHeight / 2),
  //           behavior: 'smooth'
  //         });
  //       }
  //     }
  //   }
  // }
  //

  private scrollToFocussedItem(): void {
    // Assuming each item row has an id attribute like `item-row-${index}`
    const focussedItemIndex = this.items.findIndex(item => item === this.focussedItem);
    const elementId = `item-row-${focussedItemIndex}`;
    const element = document.querySelector(`#${elementId}`);

    if (element) {
      console.log('scrollToFocussedItem - element', element);
      const yOffset = element.getBoundingClientRect().top + window.pageYOffset - 10; // someOffset is optional for extra spacing
      window.scrollTo({top: yOffset, behavior: 'smooth'});
    }
  }

  // private scrollToFocussedItem(): void {
  //   console.log('scrollToFocussedItem - this.focussedItem, this.rows', this.focussedItem, this.rows);
  //   if (this.focussedItem && this.rows) {
  //     const index = this.items.findIndex(item => item === this.focussedItem);
  //     const rowElement = this.rows.toArray()[index]?.nativeElement;
  //     console.log('scrollToFocussedItem - rowElement', rowElement);
  //     if (rowElement) {
  //       rowElement.scrollIntoView({ behavior: 'smooth', block: 'nearest' });
  //     }
  //
  //     this.cdRef.detectChanges();
  //   }
  // }

  // TODO: add typing for property, ... keyOf
  // TODO: Check why Partial<> is needed
  setFocussedItem(item: Partial<IDriverDeliveryProduct>, quantityType: QuantityType): void {
    this.focussedItem = item;
    this.focussedQuantityType = quantityType;
    this.itemFocus.emit({ item, quantityType });

    // setTimeout(() => {
    //   this.scrollToFocussedItem();
    //   this.cdRef.detectChanges();
    // }, 100);
  }

  itemIsFocussed(item: Partial<IDriverDeliveryProduct>, quantityType: QuantityType): boolean {
    return this.focussedItem === item && this.focussedQuantityType === quantityType;
  }
}
