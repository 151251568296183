import { Component, inject } from '@angular/core';
import { CommonModule } from '@angular/common';
import { IonicModule } from '@ionic/angular';
import { DeliveryDetailsPageService } from '../../delivery-details-page.service';
import { FormsModule } from '@angular/forms';
import { InputComponent } from '../../../../core/components/input/input.component';
import { EpsonPrintCommunicationService } from '../../../../shared/services/print/epson-print-communication.service';
import { AppIndexedDb } from '../../../../shared/services/db/app-indexed-db.service';

@Component({
  selector: 'prf-delivery-market-info-tab',
  templateUrl: './delivery-market-info-tab.component.html',
  styleUrls: ['./delivery-market-info-tab.component.scss'],
  standalone: true,
  imports: [IonicModule, CommonModule, FormsModule, InputComponent]
})
export class DeliveryMarketInfoTabComponent {
  protected deliveryDetailsPageService = inject(DeliveryDetailsPageService);
  private printService = inject(EpsonPrintCommunicationService);
  private db = inject(AppIndexedDb);

  async onPrintDelivery(deliveryId: number) {
    try {
      const processedDelivery = await this.db.getProcessedDeliveryById(deliveryId);
      if (processedDelivery?.printXml) {
        this.printService.printDeliverySlip(
          null!, // We don't need the delivery object since we have the printXml
          false,
          processedDelivery.signature,
          processedDelivery
        );
      }
    } catch (error) {
      console.error('Error printing delivery:', error);
    }
  }
}
