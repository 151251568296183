<ng-container *ngIf="currentDelivery$ | async as currentDelivery">
  <ion-content>
    <div class="ion-padding">

<!--      TODO: auf delivery finalized/completed seite soll der druck button an der selben stelle platziert sein, jedoch ohne ENTWURFS-hinweis, da bereits final-->

      <div class="text-center">
        <ion-button class="mt-2 mb-3"
                    (click)="onClickPrintDraftDeliverySlip(currentDelivery)"
                    fill="outline">
          <ion-icon name="print-outline" class="mr-1" />
          Drucken (ENTWURF)
        </ion-button>
      </div>

      <ng-container [ngSwitch]="wizardStep">
        <ng-container *ngSwitchCase="1">
          <h1 class="mt-1 mb-1">{{ wizardStep }}. Lieferschein mit Mengen</h1>
          <span style="color: #868f9b;">Tragen Sie ausgelieferte Mengen und Retouren ein.</span>
          <prf-product-amount-grid class="mt-3"
                                   [items]="deliveryProducts"
                                   (itemFocus)="setFocussedAmountGridItem($event)"
                                   (productsChange)="onProductsChange($event)" />
        </ng-container>

        <ng-container *ngSwitchCase="2">
          <!--        TABLE HERVORHERUBG-->
          <h1 class="mt-1 mb-1">{{ wizardStep }}. Lieferschein prüfen</h1>
          <span style="color: #868f9b;">Prüfen Sie die eingetragenen Mengen und Retouren.</span>
          <prf-product-amount-grid class="mt-3"
                                   [items]="deliveryProducts"
                                   [isEditable]="false" />
        </ng-container>

        <ng-container *ngSwitchCase="3">
          <h1 class="mt-1 mb-3">{{ wizardStep }}. Lieferung annehmen</h1>

          <p style="margin-bottom: 0;">Die Kühltemperatur von 3°C - 7°C wurde eingehalten. Die Lieferung wurde geprüft
            und
            entgegengenommen.</p>

          <div class="mt-4">
            <label>Ihre Nachricht an PALDO</label>
            <!-- TODO: extract reusable prf-textarea and prf-input - with centralized styling -->
            <ion-textarea fill="solid"
                          maxlength="1000"
                          [(ngModel)]="messageFromMarketToOffice"
            ></ion-textarea>
          </div>

          <!-- Datum -->
          <div style="margin-top: 20px;" class="flex justify-content-between">
            <div>
              <label>Lieferdatum:</label>
              <ion-text>
                <h3 style="margin-top: 3px;">{{ currentDelivery.deliveryDate | prfDate:'dd., DD.MM.YYYY' }}</h3>
              </ion-text>
            </div>
            <div>
              <label>Zeitpunkt:</label>
              <ion-text>
                <h3 style="margin-top: 3px;">{{ currentTimeDate | prfDate:'HH:mm' }} Uhr</h3>
              </ion-text>
            </div>
          </div>

          <!-- Warenannahme durch -->
          <div style="margin-top: 20px;">
            <ion-label position="stacked">Warenannahme durch:</ion-label>
            <div class="flex">
              <ion-select [(ngModel)]="selectedTitle"
                          interface="popover"
                          placeholder="Anrede"
                          class="ml-2"
                          style="flex-basis: 120px;">
                <ion-select-option value="Herr">Herr</ion-select-option>
                <ion-select-option value="Frau">Frau</ion-select-option>
                <ion-select-option value="keine Angabe">keine Angabe</ion-select-option>
              </ion-select>
              <prf-input [ngModel]="signaturePersonName"
                         (ngModelChange)="onSignaturePersonNameChange($event)"
                         (blur)="onSignaturePersonNameChange(this.signaturePersonName)"
                         [maxlength]="60"
                         class="flex-grow"
                         placeholder="Mitarbeitername"></prf-input>
            </div>
          </div>

          <!-- Unterschrift Markt-Mitarbeiter -->
          <div>
            <div>
              <div class="ion-padding">

                @if (hasSignature()) {
                  <div class="prf-color-success flex">
                    <ion-icon name="checkmark-circle-sharp" class="mr-1"></ion-icon>
                    Unterschrieben
                  </div>
                }

                <ion-button (click)="isSignatureModalOpen = true;"
                            [fill]="hasSignature() ? 'outline' : undefined"
                            expand="block"
                            [disabled]="!signaturePersonName"
                >
                  @if (hasSignature()) {
                    <div style="margin-left: auto;">Erneut<br />unterschreiben</div>
                    <img [src]="getSignatureBase64Data()"
                         style="height: 75px; position: absolute; left: -7px; top: -24px;">
                  } @else {
                    <ion-icon name="pencil-outline" class="mr-2"></ion-icon>
                    Jetzt unterschreiben
                  }
                </ion-button>
              </div>

            </div>
          </div>

          <div style="margin-top: 20px;">
            <label class="flex mb-1">
              <ion-icon name="mail-outline" class="mr-1"></ion-icon>
              Lieferschein wird gesendet an:
            </label>
            <ion-text>
              <h5 style="margin-top: 3px; font-size: 1rem;">
                {{ currentDelivery.marketDeliverySlipEmail }}
              </h5>
            </ion-text>
          </div>
        </ng-container>

      </ng-container>

      <!--    MODALS -->
      <prf-amount-number-pad-modal [isOpen]="!!amountGridFocusedItem && isNumberPadModalOpen"
                                   [item]="amountGridFocusedItem"
                                   [quantityType]="amountGridFocusedQuantityType!"
                                   (confirm)="onNumberPadConfirm()"
                                   (next)="onNumberPadNext()"
                                   (numberChange)="onNumberPadNumberChangeEvent($event)"
                                   (didDismiss)="onDidDismissNumPadModal()" />

      <prf-signature-pad-modal [isOpen]="isSignatureModalOpen"
                               [name]="getSignaturePersonName()"
                               (didDismiss)="onDidDismissSignaturePadModal($event)" />
    </div>

  </ion-content>

  <ion-footer class="dark">
    <div class="ion-padding footer-button-wp">
      <ion-button *ngIf="shouldShowPrevButton()"
                  fill="clear"
                  (click)="onClickPrevStep()">
        <ion-icon slot="start" name="chevron-back-outline"></ion-icon>
        Zurück
      </ion-button>

      <ion-button *ngIf="shouldShowNextStepButton()"
                  style="margin-left: auto;"
                  (click)="onClickNextStep()">Weiter
        <ion-icon name="chevron-forward-outline"></ion-icon>
      </ion-button>

      <ion-button *ngIf="shouldShowFinishDelivery()"
                  color="success"
                  [disabled]="isFinishDeliveryDisabled(currentDelivery)"
                  (click)="onClickFinishDelivery(currentDelivery)"
      >
        Abschließen
        <ion-icon slot="end" name="checkmark-circle-outline"></ion-icon>
      </ion-button>
    </div>
  </ion-footer>
</ng-container>

<!--SIGNATURE-->
<!--https://github.com/szimek/signature_pad-->
<!--consider trimming the canvas-->
<!--https://medium.com/@ganapathy2000subramanian/building-a-signature-pad-in-angular-a-step-by-step-guide-%EF%B8%8F-de3d753568e3-->
<!--validation: this.signatureNeeded = this.signaturePad.isEmpty();-->
<!--https://github.com/almothafar/angular-signature-pad/blob/master/projects/angular-signature-pad/src/lib/angular-signature-pad.component.ts-->
