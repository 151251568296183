import { Component, inject } from '@angular/core';
import { CommonModule } from '@angular/common';
import { IonicModule } from '@ionic/angular';
import { MOBILE_ROUTE_PATHS } from '../../app.routes';
import { Router } from '@angular/router';
import { IDriverDelivery } from '@prf/shared/domain';
import { ProcessedDelivery } from '../../shared/services/db/app-indexed-db.service';
import { EpsonPrintCommunicationService } from '../../shared/services/print/epson-print-communication.service';

@Component({
  selector: 'prf-delivery-done-details',
  templateUrl: './delivery-done-details.page.html',
  styleUrls: ['./delivery-done-details.page.scss'],
  standalone: true,
  imports: [IonicModule, CommonModule],
})
export class DeliveryDoneDetailsPage {
  private router = inject(Router);
  private printService = inject(EpsonPrintCommunicationService);
  // protected deliveryMarketsTourPageService = inject(DeliveryMarketsTourPageService);
  // private currentDeliveryTourDate: string | null = null;
  currentDelivery!: IDriverDelivery;
  processedDelivery!: ProcessedDelivery;

  constructor() {
    // this.deliveryMarketsTourPageService.deliveryTour$
    //   .pipe(takeUntilDestroyed())
    //   .subscribe((deliveryTour: DeliveryTour) => {
    //     this.currentDeliveryTourDate = deliveryTour.date;
    //   });

    // Note: Call current navigation within constructor, because navigation has potentially finished already within ngOnInit.
    const navigation = this.router.getCurrentNavigation();
    if (navigation?.extras.state) {
      this.processedDelivery = navigation.extras.state['processedDelivery'];
      this.currentDelivery = navigation.extras.state['originalDelivery'];
    }
  }

  onPrintProcessedDelivery() {
    if (this.processedDelivery) {
      // Create delivery object with updated products
      const deliveryForPrinting = {
        ...this.currentDelivery,
        // Note: original delivery (from BE) always has 0 quantities, therefore the entered quantities are being used here.
        deliveryProducts: this.processedDelivery.deliveryProducts
      };

      this.printService.printDeliverySlip(
        deliveryForPrinting,
        false,
        this.processedDelivery.signature,
        this.processedDelivery
      );
    }
  }

  onClickBackToTour() {
    this.goToCurrentDeliveryTourPage();
  }

  private goToCurrentDeliveryTourPage(): void {
    const today = new Date().toISOString().split('T')[0]; // Format: YYYY-MM-DD
    this.router.navigate([
      MOBILE_ROUTE_PATHS.DELIVERY_MARKETS_TOUR + '/' + today,
    ]);
  }
}
